<template>
  <section>
    <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">Listado de Clientes</p>
        <Button @click="actionModal" class="ml-auto rounded-md" label="Crear Cliente"/>
      </div>
      <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
        <!-- filtro por codigos de articulo -->
        <div class="w-full">
          <label class="text-xs text-gray-600" for="cod_bod">Código</label>
          <InputText class="w-full" id="cod_bod" @keyup.enter="listar" v-model="filtros.CardCode"/>
        </div>
        <!-- filtro por descripcion -->
        <div class="w-full">
          <label class="text-xs text-gray-600" for="descripcion">Nombre</label>
          <InputText class="w-full" id="descripcion" v-model="filtros.CardName"/>
        </div>
        <!-- filtro por modalidad -->
        <div class="flex gap-2">
          <Button @click="listar" class="mt-5 mb-1.5 rounded-md" label="Buscar"/>
          <Button @click="limpiarFiltros" class="mt-5 mb-1.5 rounded-md p-button-outlined p-button-secondary"
                  label="Limpiar"/>
        </div>
      </div>
      <div class="w-full my-2">
        <DataTable class="p-datatable-sm text-xs" :value="datosDatatable.rows" responsiveLayout="scroll">
          <Column header="Codigo">
            <template #body="{data}">
              <div class="pt-3">
                <p>{{ data.CardCode }}</p>
                <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                  <button class="underline italic" @click='edit(data)'>Editar</button>
                </div>
              </div>
            </template>
          </Column>
          <Column field="CardName" header="Nombre Corto"></Column>
          <Column field="U_PHR_CardName" header="Nombre Largo"></Column>
          <Column field="TipoCliente" header="Tipo"></Column>
          <Column header="Acciones">
            <template #body="{data}">
              <div class="p-2 rounded-md">
                <Button
                  @click='remove(data.CardCode)'
                  severity="danger"
                  v-tooltip="'Eliminar'"
                  class='p-button-rounded p-button-icon-only mx-2'
                >
                  <i class="pi pi-trash text-white"></i>
                </Button>
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
                   :rows="limit"
                   :totalRecords="parseInt(datosDatatable.count)"
                   :rowsPerPageOptions="[2,10,20,30,100]"
                   @page="onPage($event)"
        />
      </div>
    </div>
    <Dialog
      :header="update ? 'Actualizar Cliente' : 'Crear Cliente'"
      v-model:visible="displayModal"
      :breakpoints="{'960px': '100vw', '640px': '100vw'}"
      :style="{width: '30vw'}"
      :modal="true"
    >
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span>CardCode</span>
          <InputText v-model="newItem.CardCode" class="w-full" placeholder="Codigo"/>
        </div>
        <div class="flex flex-col gap-2">
          <span>Nombre Corto</span>
          <InputText v-model="newItem.CardName" class="w-full" placeholder="Nombre"/>
        </div>
        <div class="flex flex-col gap-2">
          <span>Nombre Largo</span>
          <InputText v-model="newItem.U_PHR_CardName" class="w-full" placeholder="Nompre completo"/>
        </div>
        <div class="flex flex-col gap-2">
          <span>Tipo</span>
          <Dropdown v-model="newItem.TipoCliente" :options="tipoCL" placeholder="Seleccione un tipo"/>
        </div>
      </div>
      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" @click="actionModal" class="p-button-xs p-button-danger"/>
        <Button v-if="!update" label="Guardar" icon="pi pi-check" @click="create" class="p-button-xs p-button-success"
                autofocus/>
        <Button v-else label="Actualizar" icon="pi pi-check" @click="editItem" class="p-button-xs p-button-success"
                autofocus/>
      </template>
    </Dialog>
    <Toast/>
  </section>
</template>
<script setup>
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
import Service from '../../../../../services/ov-cliente.service'
import { onMounted, ref, computed } from 'vue'

const toast = useToast()
const _Service = ref(new Service())

// Referencias
const update = ref(false)
const idUpdate = ref(null)
const datosDatatable = ref([])
const newItem = ref({})
const tipoCL = ref(['EPS', 'ARL'])
const limit = ref(10)
const offset = ref(0)
const filtros = ref({
  Codigo: null,
  Nombre: null
})
const displayModal = ref(false)
const params = computed(() => {
  return {
    limit: limit.value,
    offset: offset.value,
    ...filtros.value
  }
})

// Metodos
const onPage = ({ first, page, pageCount, rows }) => {
  limit.value = rows
  listar(params.value)
}
const listar = () => {
  const object = {
    ...params.value,
    limit: limit.value,
    offset: offset.value
  }
  _Service.value.paginate(object).then(({ data }) => {
    datosDatatable.value = data
  })
}
const edit = (data) => {
  idUpdate.value = data.CardCode
  actionModal()
  update.value = true
  newItem.value.CardCode = data.CardCode
  newItem.value.CardName = data.CardName
  newItem.value.U_PHR_CardName = data.U_PHR_CardName
  newItem.value.TipoCliente = data.TipoCliente
}
const editItem = () => {
  _Service.value.put({ id: idUpdate.value, body: newItem.value }).then(({ data }) => {
    if (data.error) {
      console.error('Error al actualizar la modalidad, por favor intente nuevamente')
    } else {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Modalidad actualizada exitosamente', life: 3000 })
      listar()
      actionModal()
    }
  })
}
const remove = (id) => {
  Swal.fire({
    title: 'Advertencia',
    text: '¿Está seguro que desea eliminar la modalidad?',
    showDenyButton: true,
    denyButtonText: 'Cancelar',
    confirmButtonText: 'Eliminar'
  }).then((result) => {
    if (result.isConfirmed) {
      _Service.value.delete(id).then(({ data }) => {
        if (data.error) {
          console.error('Error al eliminar la modalidad, por favor intente nuevamente')
        } else {
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Modalidad eliminada exitosamente', life: 3000 })
          listar()
        }
      })
    } else if (result.isDenied) {
      Swal.fire('Modalidad no eliminada', '', 'info')
    }
  })
}
const limpiarFiltros = () => {
  filtros.value = {
    descripcion: null,
    value: null
  }
  listar()
}
const actionModal = () => {
  update.value = false
  displayModal.value = !displayModal.value
  newItem.value = {}
}
const create = () => {
  if (!newItem.value.CardCode || !newItem.value.CardName || !newItem.value.U_PHR_CardName || !newItem.value.TipoCliente) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor ingrese todos los campos', life: 3000 })
    return
  }
  newItem.value.CardName.toUpperCase()
  newItem.value.U_PHR_CardName.toUpperCase()
  _Service.value.post(newItem.value).then(({ data }) => {
    toast.add({ severity: 'success', summary: 'Exito', detail: 'Modalidad creada exitosamente', life: 3000 })
    console.log(data)
    actionModal()
    listar()
  }).catch((error) => {
    toast.add({ severity: 'error', summary: 'Error', detail: 'Error al crear la modalidad', life: 3000 })
    console.error(error)
  })
}

onMounted(() => {
  listar({
    limit,
    offset
  })
})
</script>
<style>
.p-dropdown-label {
  font-size: 0.75rem !important
}

.tipoId .p-dropdown-trigger {
  display: none !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-input-placeholder { /* Edge */
  color: gray;
}

::-webkit-scrollbar {
  width: 0.4rem;
}
</style>
